<template>
<div>
<div class="tablist">
    <ul class="tabmenu">
        <li 
        v-for="item in list"
        :key="item.id"
        :class="{currentElement : currentKey === item.id}"
        @click="handler(item.title,item.id)"
        >{{item.title}}</li>
    </ul>
  </div>
  <TablistContent :title="title"></TablistContent>
</div>
</template>

<script>
import TablistContent from './tablistcontent'
export default {
  components : {
      TablistContent
  },
  name: 'Tablist',
  data () {
      return {
         list: [
             {
               id : 1,
               title: "红十字运动知识"
             },
             {
               id : 2,
               title: "中国红十字会发展历程"
             },
             {
               id : 3,
               title: "四川省红十字会发展历程"
             }
         ],
         title: "红十字运动知识",
         currentKey: 1
      }
    },
    methods : {
        handler (title,id) {
            this.title = title
            this.currentKey = id
        }
    }
}
</script>

<style lang="less" scoped>
.tablist {
    margin-top: 10px;
    margin-bottom: 16px;
    .tabmenu {
        display: flex;
        font-size: 23px;
        color: #999999;
        font-weight: bolder;
        li {
            margin-right: 81px;
            cursor: pointer;
            display: flex;
            &::before {
                content: "";
                display: block;
                display: none;
                width: 30px;
                height: 23px;
                background: url('../../../assets/img/title.png') no-repeat center center;
            }
        }
        .currentElement {
            color: #333333;
             &::before {
                display: block;
            }
        }
    }
}
</style>
